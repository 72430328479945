import React, { useState, useEffect } from "react";
import "./Header.css";
import { useHistory } from "react-router-dom";
import useScrollPosition from "@react-hook/window-scroll";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "./Menu";
import CloseIcon from "@material-ui/icons/Close";

function Header() {
  const [whiteHeader, setWhiteHeader] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();
  const scrollY = useScrollPosition(60);

  useEffect(() => {
    if (showMenu && !whiteHeader) {
      setWhiteHeader(true);
    } else if (scrollY > 100 && !whiteHeader) {
      setWhiteHeader(true);
    } else if (!showMenu && scrollY < 100 && whiteHeader) {
      setWhiteHeader(false);
    }
  }, [scrollY, whiteHeader, showMenu]);

  return (
    <div className="header__container">
      <div className={whiteHeader ? `headerWhite` : `header`}>
        <div className="header__title" onClick={() => history.push("/")}>
          <img
            src="https://i.ibb.co/RcbkqBc/logo.png"
            alt=""
            className="header__titleLogo"
          />
          <div className="header__titleText">
            <h2>Kathleen Reddick</h2>
            <p>Front-end Developer</p>
          </div>
        </div>
        <div className="header__titleMb" onClick={() => history.push("/")}>
          <img src="https://i.ibb.co/RcbkqBc/logo.png" alt="" />
        </div>
        <div className="header__options">
          <p onClick={() => history.push("/")}>Home</p>
          <p onClick={() => history.push("/projects")}>Experience</p>
          <p>
            <a target="_top" href={"mailto:kd.re@live.ca"}>
              Contact
            </a>
          </p>
        </div>
        <div className="header__optionsMenuMb">
          {!showMenu ? (
            <MenuIcon
              className="header__optionsMenuMbIcon"
              onClick={() => setShowMenu(true)}
            />
          ) : (
            <CloseIcon onClick={() => setShowMenu(false)} />
          )}
        </div>
      </div>
      <div onClick={() => setShowMenu(false)}>{showMenu && <Menu />}</div>
    </div>
  );
}

export default Header;
