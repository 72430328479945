import React from "react";
import "./Projects.css";
import Card from "../components/Card";
import Footer from "../components/Footer";
// import lizziPaws from "../images/LizziPaws.png";
import itadaki from "../images/itadakihealthy.png";
import appAttic from "../images/appAttic1.jpg";
import bluecode from "../images/bluecode.png";
import Hobbies from "../components/Hobbies";
import lizziPaws from "../images/LizziPaws.png";

function Projects() {
  return (
    <div className="projects">
      <div className="projects__intro">
        <h3>Experience</h3>
        <p>Work, projects, and other exciting undertakings</p>
      </div>
      <div className="projects__job flex flex-col items-center">
        <p className="text-center  px-6">
          I'm currently enjoying working in
          <br /> partnership with my great colleagues at
        </p>
        <a href="https://bluecode.co.jp/" target="_blank" rel="noreferrer">
          <img src={bluecode} alt="bluecode" />
        </a>

        <span className="projects__line mt-14 mb-14">&nbsp;</span>
        <p className="text-center  px-6">
          Outside of my work at bluecode,
          <br /> I've had the chance to collaborate with <br /> all kinds of
          interesting people.
        </p>
      </div>
      <div className="projects__allRows">
        <div className="projects__row">
          <Card
            description="Custom sizechart maker for online store (PC only)"
            siteLink="https://sizechartmaker.netlify.app/"
            buttonText="View Website"
            overlayImg={lizziPaws}
          />
          <Card
            description="itadakihealthy Coaching (vegan nutrition coaching)"
            siteLink="https://www.itadakihealthy.com/"
            buttonText="View Website"
            overlayImg={itadaki}
          />
          <Card
            description="Bimikan Taiwanese Cuisine (restaurant website)"
            siteLink="https://bimikan.netlify.app/"
            buttonText="View Website"
            overlayImg="https://i.ibb.co/CKHY67D/bimikan-Tab.jpg"
          />
        </div>
        <div className="projects__row">
          <Card
            description="App Attic (Shopify App Creators)"
            siteLink="https://www.appattic.com/"
            buttonText="View Website"
            overlayImg={appAttic}
          />
          <Card
            description="Madame Surtô Spa and Beauty Salon"
            siteLink="https://www.spamadamesurto.com/"
            overlayImg="https://i.ibb.co/tqdg7c9/beauty-Spa.jpg"
            buttonText="View Website"
          />
          <Card
            description="The Happy Teacup ( online tea store)"
            siteLink="https://www.thehappyteacup.com/"
            buttonText="View Website"
            overlayImg="https://www.thehappyteacup.com/static/media/svglogo.ee607ac4.svg"
          />
        </div>
      </div>
      <div className="projects__allRowsTwo">
        <div className="projects__row">
          <Card
            description="Custom sizechart maker for online store (PC only)"
            siteLink="https://sizechartmaker.netlify.app/"
            buttonText="View Website"
            overlayImg={lizziPaws}
          />
          <Card
            description="itadakihealthy Coaching"
            siteLink="https://www.itadakihealthy.com/"
            buttonText="View Website"
            overlayImg={itadaki}
          />
        </div>
        <div className="projects__row">
          <Card
            description="Bimikan (Taiwanese Restaurant)"
            siteLink="https://bimikan.netlify.app/"
            buttonText="View Website"
            overlayImg="https://i.ibb.co/CKHY67D/bimikan-Tab.jpg"
          />
          <Card
            description="App Attic (Shopify App Creators)"
            siteLink="https://www.appattic.com/"
            buttonText="View Website"
            overlayImg={appAttic}
          />
        </div>
        <div className="projects__row">
          <Card
            description="Madame Surtô Spa and Beauty Salon"
            siteLink="https://www.spamadamesurto.com/"
            overlayImg="https://i.ibb.co/tqdg7c9/beauty-Spa.jpg"
            buttonText="View Website"
          />
          <Card
            description="The Happy Teacup"
            siteLink="https://www.thehappyteacup.com/"
            buttonText="View Website"
            overlayImg="https://www.thehappyteacup.com/static/media/svglogo.ee607ac4.svg"
          />
        </div>
      </div>
      <div className="projects__other">
        <h2 className="projects__otherTitle">Personal Projects</h2>
        <p className="projects__otherText">
          In my free time, I like learning new things and building something
          with what I've learned. Here are a few fun projects I've been working
          on recently.
        </p>

        <Hobbies />
      </div>
      <Footer />
    </div>
  );
}

export default Projects;
