import React from "react";
import "./About.css";
import { useHistory } from "react-router-dom";
import aboutPageColorsVideo from "../images/colorsVideo.mp4";
import Footer from "../components/Footer";
import avatarImg from "../images/avatar.svg";
import Skills from "../components/Skills";

function About() {
  const history = useHistory();

  return (
    <div className="about">
      <div className="about__videoPreload" />
      <video autoPlay muted loop className="about__video">
        <source src={aboutPageColorsVideo} type="video/mp4" />
      </video>
      <div className="about__title">
        <p>Hi there! I'm Kathleen. </p>
      </div>
      <div className="about__titleMb">
        <p className="about__titleMbBigP">Front-end Developer </p>
        <p className="about__titleMbSmallP">
          I design and code beautiful and functional web and mobile applications.
        </p>
      </div>
      <div className="about__bottom">
        <div className="about__bottomText">
          <img src={avatarImg} className="about__avatar" alt="" />
          <p>
            {" "}
            A creative and detail-oriented front-end developer and designer.{" "}
          </p>
          <p>
            I design and code beautiful and functional web and mobile applications.{" "}
          </p>
          <p>
            {" "}
            Looking to collaborate on a project? I'd love to{" "}
            <a href={"mailto:kd.re@live.ca"} className="about__titleEmail">
              hear from you!
            </a>
          </p>
          <h4>Adaptive · Passionate · Innovative</h4>
          <button className="about__bottomTextButton">
            <span>
              <a href={"mailto:kd.re@live.ca"} className="nostyle">
                Start a conversation
              </a>
            </span>
          </button>
        </div>
        <div className="about__bottomTextMb">
          <img src={avatarImg} className="about__avatar" alt="" />
          <p>
            Hi there! I'm Kathleen. I design and create user-friendly web and
            mobile applications. Looking to collaborate on a
            project? I'd love to{" "}
            <a href={"mailto:kd.re@live.ca"} className="about__titleEmail">
              hear from you
            </a>
            !
          </p>
          <h4>Adaptive · Passionate · Innovative</h4>
          <button className="about__bottomTextButton">
            <span>
              <a href={"mailto:kd.re@live.ca"} className="nostyle">
                Get in touch
              </a>
            </span>
          </button>
        </div>
        <div className="about__praise">
          <p className="about__praiseText">
            "I highly recommend Kathleen. She works hard at everything she does.
            She is uncompromising in her pursuit of high goals, always takes her
            work seriously, strives to improve, and gets along well with
            everyone. I have the utmost confidence in her."
          </p>
          <p className="about__praiseAuthor">
            <span>Yuka Ikegami</span>Advantage English Sakae
          </p>
          <Skills />
        </div>
        <div className="about__buttons">
          <button onClick={() => history.push("/projects")}>
            <span>Projects</span>
          </button>
          <button>
            <span>
              <a href="https://github.com/Code-Kat" className="nostyle">
                GitHub
              </a>
            </span>
          </button>
          <button>
            <span>
              <a href={"mailto:kd.re@live.ca"} className="nostyle">
                Email
              </a>
            </span>
          </button>
          <button>
            <span>
              <a
                href="https://www.linkedin.com/in/kathleen-reddick-301072116/"
                className="nostyle"
              >
                LinkedIn
              </a>
            </span>
          </button>
        </div>
      </div>
      <div className="about__footerMb">
        <Footer />
      </div>
    </div>
  );
}

export default About;
