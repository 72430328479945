import React, { useEffect, useRef, useState } from "react";
import "./Card.css";
import Fade from "@material-ui/core/Fade";
import useOnScreen from "../utils/helperFunctions";

function Card({ description, siteLink, overlayImg, buttonText }) {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isVisible && !checked) {
      setChecked(true);
    }
  }, [isVisible, checked]);

  return (
    <div ref={ref}>
      <Fade in={checked} timeout={1500}>
        <div className="card">
          <div className="card__main">
            <p> {description} </p>
            <div className="card__button">
              <a href={siteLink} target="_blank" rel="noreferrer">
                <span>{buttonText}</span>
              </a>
            </div>
          </div>
          <div className="card__overlay">
            <img alt="" src={overlayImg} />
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Card;
