import React from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <img src="https://i.ibb.co/RcbkqBc/logo.png" alt="" />
      <h4>Dedicated to learning, creating, and improving.</h4>
      <div className="footer__icons text-4xl">
        <a
          href={"mailto:kd.re@live.ca"}
          className="nostyle"
          target="_blank"
          rel="noreferrer"
        >
          <MailOutlineIcon className="footer__icon" fontSize="inherit" />
        </a>
        <a href="https://github.com/Code-Kat/" className="nostyle">
          <GitHubIcon className="footer__icon" fontSize="inherit" />
        </a>
        <a
          href="https://www.linkedin.com/in/kathleen-reddick-301072116/"
          className="nostyle"
        >
          <LinkedInIcon className="footer__icon" fontSize="inherit" />
        </a>
      </div>
      <p>
        {" "}
        Handcrafted by me using icons made by Freepik, Chanut-is-Industries, and
        Rabit Jes from Flaticon.com
      </p>
    </div>
  );
}

export default Footer;
