import React from "react";
import "./Hobbies.css";
import salad from "../images/salade.png";
import game from "../images/game.png";
import dart from "../images/dart.png";

function Hobbies() {
  return (
    <div className="hobbies">
      <div className="hobbies__column1">
        <img className="hobbies__img" alt="salad" src={salad} />
        <h3 className="hobbies__title">Is it Vegan? Japan</h3>
        <p className="hobbies__p">
          A helpful app for vegans in Japan who quickly want to know if a
          product is vegan or not.
          <br />
          <br />
          React Native, graphcms, Firebase.
        </p>
      </div>
      <div className="hobbies__column2">
        <img className="hobbies__img" alt="" src={game} />
        <h3 className="hobbies__title">C✙✙</h3>
        <p className="hobbies__p">
          The thought came to me over the New Year's break: Wouldn't it be fun
          to create my own video game? I'm still a long way from making anything
          impressive but am having a blast on my journey there.
        </p>
      </div>
      <div className="hobbies__column3">
        <img className="hobbies__img" alt="" src={dart} />
        <h3 className="hobbies__title">Flutter/Dart</h3>
        <p className="hobbies__p">
          I'm still playing around with simple apps, but hoping to build
          something fun and exciting soon!
          <br />
          I still prefer React Native for now, but I haven't ruled out switching
          to team Flutter just yet.
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}

export default Hobbies;
