import React from "react";
import { useHistory } from "react-router-dom";
import "./Menu.css";

function Menu() {
  const history = useHistory();
  return (
    <div className="menu">
      <div className="menu__container">
        <p onClick={() => history.push("/")}>Home</p>
        <p onClick={() => history.push("/projects")}>Projects</p>
        <p>
          {" "}
          <a className="nostyle" target="_top" href={"mailto:kd.re@live.ca"}>
            Get in touch
          </a>
        </p>
        <p>
          {" "}
          <a
            className="nostyle"
            href="https://www.linkedin.com/in/kathleen-reddick-301072116/"
          >
            LinkedIn
          </a>
        </p>
        <p>
          {" "}
          <a className="nostyle" href="https://github.com/Code-Kat/">
            GitHub
          </a>
        </p>
      </div>
    </div>
  );
}

export default Menu;
