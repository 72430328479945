
import './App.css';
import React from "react";
import Header from "./components/Header";
import About from "./pages/About";
import Projects from "./pages/Projects"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import {Helmet} from "react-helmet"


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Switch>
          <Route path="/projects">
            <Helmet>
            <title>Projects | Kathleen Reddick</title>
            <meta name="Kathleen Reddick || Front-End Developer" content="Kathleen Reddick - React web and mobile developer. Here is some of my rencent work." />
            </Helmet>
            <Header />
            <Projects />
          </Route>
          <Route path="/">
          <Helmet>
            <title>Home | Kathleen Reddick</title>
            <meta name="Kathleen Reddick || Front-End Developer" content="Kathlen Reddick - React web and mobile front-end developer." />
        </Helmet>
            <Header />
            <About />
          </Route>
          </Switch>
      </div> 
    </Router>
  );
}

export default App;
